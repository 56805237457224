import React from 'react';
import ThemeProvider from './ThemeProvider';
import GlobalStyle from './GlobalStyle';

const DoczWrapper: React.FC<{ children: React.ReactChild }> = ({ children }) => (
  <ThemeProvider>
    <>
      <GlobalStyle />
      {children}
    </>
  </ThemeProvider>
);

export default DoczWrapper;
try {
    // @ts-ignore
    DoczWrapper.displayName = "DoczWrapper";
    // @ts-ignore
    DoczWrapper.__docgenInfo = { "description": "", "displayName": "DoczWrapper", "props": {} };
    // @ts-ignore
    if (typeof STORYBOOK_REACT_CLASSES !== "undefined")
        // @ts-ignore
        STORYBOOK_REACT_CLASSES["components/DoczWrapper.tsx#DoczWrapper"] = { docgenInfo: DoczWrapper.__docgenInfo, name: "DoczWrapper", path: "components/DoczWrapper.tsx#DoczWrapper" };
}
catch (__react_docgen_typescript_loader_error) { }