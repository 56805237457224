import { createGlobalStyle } from 'styled-components';
import { themeGet } from 'styled-system';

export default createGlobalStyle`
  html, body {
    width: 100%;
    height: 100%;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: ${themeGet('fonts.main', 'sans-serif')};
    color: ${themeGet('colors.black', '#000')};
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  * ::selection {
    background-color: ${themeGet('colors.gray')};
  }

  a, button, label, div, span, input, *:hover {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  h1, h2, h3, h4, h5 {
    margin: 0;
  }
`;
try {
    // @ts-ignore
    GlobalStyle.displayName = "GlobalStyle";
    // @ts-ignore
    GlobalStyle.__docgenInfo = { "description": "", "displayName": "GlobalStyle", "props": { "theme": { "defaultValue": null, "description": "", "name": "theme", "required": false, "type": { "name": "DefaultTheme | undefined" } }, "suppressMultiMountWarning": { "defaultValue": null, "description": "", "name": "suppressMultiMountWarning", "required": false, "type": { "name": "boolean | undefined" } } } };
    // @ts-ignore
    if (typeof STORYBOOK_REACT_CLASSES !== "undefined")
        // @ts-ignore
        STORYBOOK_REACT_CLASSES["components/GlobalStyle.tsx#GlobalStyle"] = { docgenInfo: GlobalStyle.__docgenInfo, name: "GlobalStyle", path: "components/GlobalStyle.tsx#GlobalStyle" };
}
catch (__react_docgen_typescript_loader_error) { }