import React from 'react';
import useTab from '../src/hooks/useTab';
import { ThemeProvider as OrigThemeProvider } from 'styled-components';
import { theme } from '@rm/ui';

const ThemeProvider: React.FC = props => {
  const isTabbing = useTab();

  return (
    <OrigThemeProvider theme={{ ...theme, isTabbing }}>
      <>{props.children}</>
    </OrigThemeProvider>
  );
};

export default ThemeProvider;
try {
    // @ts-ignore
    ThemeProvider.displayName = "ThemeProvider";
    // @ts-ignore
    ThemeProvider.__docgenInfo = { "description": "", "displayName": "ThemeProvider", "props": {} };
    // @ts-ignore
    if (typeof STORYBOOK_REACT_CLASSES !== "undefined")
        // @ts-ignore
        STORYBOOK_REACT_CLASSES["components/ThemeProvider.tsx#ThemeProvider"] = { docgenInfo: ThemeProvider.__docgenInfo, name: "ThemeProvider", path: "components/ThemeProvider.tsx#ThemeProvider" };
}
catch (__react_docgen_typescript_loader_error) { }